<template>
  <div>
    <b-row>
      <b-col class="mb-3" cols="12" md="6" lg="4" xl="3">
        <dashboard-big-number-a
            title="Quiz criadas"
            popover="Enquetes criadas"
        >{{ totalQuizzes }}</dashboard-big-number-a>
      </b-col>
      <b-col class="mb-3" cols="12" md="6" lg="4" xl="3">
        <dashboard-big-number-a
            title="Usuários participantes"
            popover="Usuários participantes"
        >{{ totalUsers }}</dashboard-big-number-a>
      </b-col>
      <b-col class="mb-3" cols="12" md="6" lg="4" xl="3">
        <dashboard-big-number-a
            title="Taxa de participação"
            popover="Taxa de participação"
        >{{ participationRate }}</dashboard-big-number-a>
      </b-col>
    </b-row>
    <b-row class="mb-3" v-for="(item, index) in quizList" :key="'quiz-'+index">
      <b-col>
        <dashboard-quiz-response
            :title="'Quiz ' + prefixZero(index + 1)"
            :tag="item.tag"
            :question="item.question"
            :answers="item.answers"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DashboardQuizResponse from '@/components/dashboards/dashboard-quiz-response'
import DashboardBigNumberA from '@/components/dashboards/dashboard-big-number-a'
import { quizMetrics } from '@/modules/dashboards/http/quizMetrics'
import { quizResults } from '@/modules/dashboards/http/quizResults'

function prefixZero (num) {
  return (num + '').padStart(2, '0')// num < 10 ? '0' + num : num
}

export default {
  name: 'QuizDetail',
  components: { DashboardQuizResponse, DashboardBigNumberA },
  data: () => ({
    roomId: null,
    totalQuizzes: '',
    totalUsers: '',
    participationRate: '',
    quizList: [],
    prefixZero
  }),
  computed: {
    filterDates () {
      return {
        startDate: this.$store.state.dashboards.filterStartDate,
        endDate: this.$store.state.dashboards.filterEndDates
      }
    }
  },
  methods: {
    fetchData () {
      this.roomId = this.$route.params.id
      Promise.allSettled([
        this.fetchMetrics(),
        this.fetchResult()
      ])
    },
    async fetchMetrics () {
      const data = await quizMetrics(this.roomId, this.filterDates)
      this.totalQuizzes = data.quantidade
      this.totalUsers = data.participantes
      this.participationRate = data.taxa + '%'
    },
    async fetchResult () {
      const data = await quizResults(this.roomId, this.filterDates)
      this.quizList = data.map(item => ({
        tag: item.answers.length + ' RESPOSTAS' + (item.answers.length > 1 ? 'S' : ''),
        question: item.question,
        answers: item.answers.map(item => ({
          value: item.answer,
          percentage: item.porcentagem,
          total: item.total,
          correct: item.isCorrect
        }))
      }))
    }
  },
  watch: {
    filterDates: {
      handler () {
        this.fetchData()
      },
      deep: true
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      { immediate: true }
    )
  }
}
</script>

<style scoped>

</style>
