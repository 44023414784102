import http from '@/http'
import { parseDataParam } from '@/modules/dashboards/toolbox/parseDataParam'

export async function quizResults (roomId, { startDate, endDate } = {}) {
  const data = await http.get(`/admin/metrics/rooms/${roomId}/quiz-results`, {
    params: {
      created_start: parseDataParam(startDate),
      created_end: parseDataParam(endDate)
    }
  }).then(response => response.data)
  return data.data.map(item => ({
    id: item.id,
    question: item.asking,
    answers: item.results.map(({ porcentagem, total, answer, correct }) => ({
      porcentagem,
      total,
      answer,
      isCorrect: Boolean(correct)
    }))
  }))
}
