import http from '@/http'
import { parseDataParam } from '@/modules/dashboards/toolbox/parseDataParam'

export async function quizMetrics (roomId, { startDate, endDate } = {}) {
  const data = await http.get(`/admin/metrics/rooms/${roomId}/quiz-metrics`, {
    params: {
      created_start: parseDataParam(startDate),
      created_end: parseDataParam(endDate)
    }
  }).then(response => response.data)
  return {
    quantidade: data.quiz ?? 0,
    participantes: data.quizAnswers ?? 0,
    taxa: data.rateParticipantQuiz ?? 0
  }
}
